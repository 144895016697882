<template>
  <div class="ChangePasswordsame">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./ChangePasswordPage" class="page_link">Password Change Page</router-link> > new password cannnot be same as old password
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. What is "new password cannnot be same as old password"?</h1>
      <div class="eximg">
        <div>
          <img alt="change_reset_password_error_same_en" src="../../assets/change_reset_password_error_same_en.png">
        </div>
      </div>
      <h3>Answer</h3>
      <p class="answer">
        The new password is the same as the current password.<br />
        Please enter a different password.<br />
        Also, SUZUKI SSO system does not allow you to use the last three passwords.
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
      <router-link to="./ChangePasswordnotmatch"><img id="back" src="/arrow_back.png" v-on:mouseover="imageBackChange" v-on:mouseleave="imageBackReturn" /></router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

.eximg {
  display: flex;
  flex-direction: row;
}

#forward {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

#back {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
